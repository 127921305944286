/*
 * Photo Audit Plus: Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,

 * Ionic Variables and Theming. For more info, please see:
 * http://ionicframework.com/docs/theming/

 * color theme generator:
 * https://www.ionicframework.com/docs/theming/color-generator

 * new colour creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator

 * stepped colour generator:
 * https://ionicframework.com/docs/theming/themes#stepped-colors
 */

 :root {

  --ion-font-family: 'Archivo', sans-serif;

	--ion-color-primary: #212652;
	--ion-color-primary-rgb: 33,38,82;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1d2148;
	--ion-color-primary-tint: #373c63;

	--ion-color-secondary: #5499ff;
	--ion-color-secondary-rgb: 84,153,255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #4a87e0;
	--ion-color-secondary-tint: #65a3ff;

	--ion-color-tertiary: #54d9ff;
	--ion-color-tertiary-rgb: 84,217,255;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #4abfe0;
	--ion-color-tertiary-tint: #65ddff;

  --ion-color-quaternary: #9e6bf7;
	--ion-color-quaternary-rgb: 158,107,247;
	--ion-color-quaternary-contrast: #000000;
	--ion-color-quaternary-contrast-rgb: 0,0,0;
	--ion-color-quaternary-shade: #8b5ed9;
	--ion-color-quaternary-tint: #a87af8;

	--ion-color-success: #6C9463 ;
	--ion-color-success-rgb: 108,148,99;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #5f8257;
	--ion-color-success-tint: #7b9f73;

	--ion-color-warning: #FFA500 ;
	--ion-color-warning-rgb: 255,165,0;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e09100;
	--ion-color-warning-tint: #ffae1a;

	--ion-color-danger: #FF6347 ;
	--ion-color-danger-rgb: 255,99,71;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #e0573e;
	--ion-color-danger-tint: #ff7359;

	--ion-color-light: #e1e3e5;
	--ion-color-light-rgb: 225,227,229;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #c6c8ca;
	--ion-color-light-tint: #e4e6e8;

	--ion-color-medium: #5f5f5f;
	--ion-color-medium-rgb: 95,95,95;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #545454;
	--ion-color-medium-tint: #6f6f6f;

	--ion-color-dark: #2f2f2f;
	--ion-color-dark-rgb: 47,47,47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #292929;
	--ion-color-dark-tint: #444444;

  --ion-border-color:  #FF6347;


}



@media (prefers-color-scheme: dark) {
  :root {
    /* global app variables */

    --ion-border-color:#ffffffff; //var( --ion-color-primary );

     --ion-text-color: #ffffff;
     --ion-text-color-rgb: 255,255,255;

     .logo-image {
       content:url("/assets/logos/PA+-Logo-Part-White.png");
      }
      
   
  
    ion-menu {

      ion-content{
      background-color: var( --ion-color-primary );

        ion-menu-toggle {
          padding: 20%;
      

  
          ion-label, ion-icon{
            color: #ffffff;
          }
        }
  
      }
    }
  
    
 ion-toolbar {
  --color: #fff;
}

ion-menu-button {
  color: #fff;
}

ion-card-title {
  color: #fff;
}

.ion-card-title {
  color: #fff;
}

.toolbar-button {
  --color: #fff;
}

app-settings{
  ion-content {
    --padding-start: 3%;
    --padding-end: 3%;
    --padding-top: 3%;
  
      ion-card {
        border-radius: 8px;

  
        ion-item {
         // --background: var(--ion-color-secondary);
          margin-bottom: 10px;
          border-radius: 4px;
  
          ion-icon {
           // color: var(--ion-color-primary);
           color: #fff;
          }
  
          ion-label {
           // color:  var(--ion-color-primary);
           color: #fff;
            h2 {
              font-weight: 700;
            }
  
            p {
              color: #fff;
  
            }
          }
        }
      }
  }
}

app-support {

  ion-content {
    --padding-start: 3%;
    --padding-end: 3%;
    --padding-top: 3%;
  
      .logo-container {
        max-width: 60%;
        margin-bottom: 20px;
      }
  
    .text-container {
      //--background: var(--ion-color-secondary);
      color: #fff;
      border-radius: 4px;
      --padding-top: 10px;
      --padding-bottom: 10px;
      margin-bottom: 10px;
  
      a {
        //color: var(--ion-color-primary)
        color: #fff;
      }
    }
  
  }
}

app-about{
  ion-content {
    --padding-start: 3%;
    --padding-end: 3%;
    --padding-top: 3%;
  
  
    ion-card {
      border-radius: 8px;
      
  
      ion-card-header {
  
        ion-item {
          max-width: 60%;
        }
      }
  
      ion-card-content {
  
        ion-item {
         // --background: var(--ion-color-secondary);
          margin-bottom: 10px;
          border-radius: 4px;
  
          ion-label {
            //color:  var(--ion-color-primary);
  
            h2 {
              font-weight: 700;
            }
  
            p {
              color: #fff;
  
            }
          }
        }
      }
    }
  }
  
}

 app-job-card {

  ion-card {
   
    border-radius: 8px;
    color: #ffffff;
  
    ion-card-header {
      padding-bottom: 0;
    }
  
    ion-label {
      margin-top: 0;
      color: #ffffff;
    }
  
    ion-item-group {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      ion-item {
        width: 50%;
        color: #ffffff;
      }
    }
  
    .secondary-text-header {
      color: #ffffff;//var(--ion-color-primary);
      margin-right: 5px;
      font-weight: bold;
    }
  
    .secondary-text-value {
      color:#ffffff; //var(--ion-color-primary);
      
    }
  
    ion-badge {
      color: #fff;
      border-radius: 2px;
      margin: 1px;
      padding: 3px 6px;
    }
  
    fa-icon {
      padding: 3px;
      margin: 6px;
      margin-left: 0;
      color: #fff;//color: var(--ion-color-secondary);
      box-shadow: 1px 1px rgba(0, 0, 0, 0.8);
    }
  
  
    svg.fa-hourglass-start {
  
      fill: red;
    }
  
  
    div[data-status="status-2"] fa-icon {
      color: var(--ion-color-primary);
    }
  
    div[data-status="status-4"] fa-icon {
      color: var(--ion-color-success);
  }
  
  }

 }

}

}



@media (prefers-color-scheme: light) {
  :root {
    /* global app variables */
    // set bg + text colours
    --ion-background-color: var( --ion-color-primary );
    // // --ion-background-color-rgb: 33, 38, 82;
    // // --ion-text-color: #ffffff;
    // // --ion-text-color-rgb: 255,255,255;

    --ion-item-background: #fff;
    --ion-border-color: var( --ion-color-primary );

    .logo-image {
      content: url("/assets/logos/PA+-Logo-Colour.png");
    }


    ion-menu {

      ion-content {
        background-color: var( --ion-color-primary );

        ion-menu-toggle {
          padding: 20%;

          ion-label, ion-icon {
            color: var(--ion-color-primary-shade);
          }
        }
      }
    }

    ion-toolbar {
      --color: #fff;
    }

    ion-menu-button {
      color: #fff;
    }

    ion-card-title {
      color: var(--ion-color-primary);
    }

    .ion-card-title {
      color: var(--ion-color-primary);
    }

    .toolbar-button {
      --color: #fff;
    }


    app-settings {
      ion-content {
        --padding-start: 3%;
        --padding-end: 3%;
        --padding-top: 3%;

        ion-card {
          border-radius: 8px;

          ion-item {
            --background: var(--ion-color-secondary);
            margin-bottom: 10px;
            border-radius: 4px;

            ion-icon {
              // color: var(--ion-color-light);
              color: #fff;
            }

            ion-label {
              //color: var(--ion-color-primary);
              color: #fff;

              h2 {
                font-weight: 700;
              }

              p {
                color: #fff;
              }
            }
          }
        }
      }
    }

    app-support {
      ion-content {
        --padding-start: 3%;
        --padding-end: 3%;
        --padding-top: 3%;

        .logo-container {
          max-width: 60%;
          margin-bottom: 20px;
        }

        .text-container {
          --background: var(--ion-color-secondary);
          color: #fff;
          border-radius: 4px;
          --padding-top: 10px;
          --padding-bottom: 10px;
          margin-bottom: 10px;

          a {
            color: var(--ion-color-primary)
          }
        }
      }
    }

    app-about {
      ion-content {
        --padding-start: 3%;
        --padding-end: 3%;
        --padding-top: 3%;


        ion-card {
          border-radius: 8px;


          ion-card-header {

            ion-item {
              max-width: 60%;
            }
          }

          ion-card-content {

            ion-item {
              --background: var(--ion-color-secondary);
              margin-bottom: 10px;
              border-radius: 4px;

              ion-label {
                color: var(--ion-color-primary);

                h2 {
                  font-weight: 700;
                }

                p {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    app-job-card {

      ion-card {
        border-radius: 8px;

        ion-card-header {
          padding-bottom: 0;
        }

        ion-label {
          margin-top: 0;
        }

        ion-item-group {
          display: flex;
          justify-content: space-between;

          ion-item {
            width: 50%;
          }
        }

        .secondary-text-header {
          color: var(--ion-color-primary);
          margin-right: 5px;
          font-weight: bold;
        }

        .secondary-text-value {
          color: var(--ion-color-primary);
        }

        ion-badge {
          color: #fff;
          border-radius: 2px;
          margin: 1px;
          padding: 3px 6px;
        }

        fa-icon {
          padding: 3px;
          margin: 6px;
          margin-left: 0;
          color: var(--ion-color-secondary);
          box-shadow: 1px 1px rgba(0, 0, 0, 0.8);
        }


        svg.fa-hourglass-start {
          fill: red;
        }


        div[data-status="status-2"] fa-icon {
          color: var(--ion-color-primary);
        }

        div[data-status="status-4"] fa-icon {
          color: var(--ion-color-success);
        }
      }
    }
  }


}



/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {
  // set bg + text colours

  --ion-toolbar-background: var( --ion-color-primary );
  --ion-tab-bar-background: var( --ion-color-primary );
  --color-selected: #ffffff;

  ion-tab-button {
    --color: var(--ion-color-medium);
  }

  ion-toolbar {
    border-bottom: var(--ion-color-secondary) solid !important
  }
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
  --ion-toolbar-background: var( --ion-color-primary );
  --ion-tab-bar-background: var( --ion-color-primary );
  --color-selected: #ffffff;

  ion-tab-button {
    --color: var(--ion-color-medium);
  }

  ion-toolbar {
    border-bottom: var(--ion-color-secondary) solid !important
  }
}

