/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* @import '@ionic/angular/css/palettes/dark.always.css'; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import '@ionic/angular/css/palettes/dark.system.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Swiper styles*/
@import '~@ionic/angular/css/ionic-swiper.css';

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./theme/variables.scss";

/* Font Family*/
@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo-VariableFont_wdth,wght.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


// placed here instead of variables.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}

ion-content {
  font-family: var( --ion-font-family);
}



/*
 * ALERT RELATED CLASSES
 * ----------------------------------------------------------------------------
 * These are the class used to style the alerts. These classes need to be in the global.scss
 * as the alerts are in the global scope.
 */
// general alert class used for styling
.alert-main-container {

  div {
    background-color: var(--ion-color-primary-shade);

    h2.alert-title {
      color: #fff;
    }

    h2.alert-sub-title {
      margin-top: 20px;
      //color: var(--ion-color-quaternary);
      color: #fff;
    }

    .alert-message {
      // color: var(--ion-color-quaternary);
      color: #fff;
    }
  }
  // styles for alert header text
  .alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
    .alert-head.sc-ion-alert-md {
      .alert-title.sc-ion-alert-md {
        color: #fff;
      }
    }
  }
}

// styling for the setting edit alert on the settings page,
// edit setting is an alert where we need to provide a css class in order to style it
.alert-input {
 /* padding-left: 2% !important;
  margin-top: 10% !important;*/
  //color: var(--ion-color-secondary) !important;
  color: #fff !important;
 // background-color: var(--ion-color-light) !important;
}

.alert-confirm-button {
  color: var(--ion-color-tertiary) !important;
}

.alert-cancel-button {
  color: var(--ion-color-light) !important;
}
